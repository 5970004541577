import React from "react"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import { Helmet } from "react-helmet"


export default ({children}) => (
    <React.Fragment>
        <div className="container">
            <Helmet>
                <title>Nicola Della Marina</title>
                <meta name="description" content="Sito personale di Nicola Della Marina"></meta>
            </Helmet>
            <Header/>
            <main className="main-content">
                {children}
            </main>
            <Footer/>
        </div>
    </React.Fragment>
)