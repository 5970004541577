import React from "react"
import Logo from "../logo/logo"
import Navigation from "../navigation/navigation"
import styles from "./header.module.css"

const Wrapper = props => <header className={styles.mainHeader}>{props.children}</header>


export default () => (
    <Wrapper>
        <Logo/>
        <Navigation/>
    </Wrapper>
)