import React from "react"

const Wrapper = props => <footer className="main-footer">{props.children}</footer>


export default () => (
    <Wrapper>
        &copy; {new Date().getFullYear()} Nicola Della Marina - <a href="mailto: info@nicoladellamarina.it">info@nicoladellamarina.it</a>
         - <a href="https://www.linkedin.com/in/nicoladellamarina/" target="blank">Linkedin</a>
         - <a href="https://twitter.com/nicoladm79" target="blank">Twitter</a>
    </Wrapper>
)